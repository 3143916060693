import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { IntlProvider, addLocaleData } from 'react-intl';
import { createBrowserHistory } from "history";

import ProtectedRoute from './components/Authorization/ProtectedRoute';
import AuthLayout from "./layouts/Auth/Auth.jsx";
import AdminLayout from "./layouts/Admin/Admin.jsx";

import "./assets/css/nucleo-icons.css";
import "./assets/scss/black-dashboard-pro-react.scss?v=1.0.0";
import "./assets/scss/custom.scss";
import "./assets/demo/demo.css";
import "react-notification-alert/dist/animate.css";

import moment from "moment";
import "moment/locale/sk";
import sk from "react-intl/locale-data/sk.js";

addLocaleData([...sk]);
moment.locale("sk");

const hist = createBrowserHistory();

class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <IntlProvider defaultLocale="sk" locale="sk">
                <Router history={hist}>
                    <Switch>
                        <Route path="/auth" render={props => <AuthLayout {...props} />} />
                        <ProtectedRoute path="/admin" component={AdminLayout} />
                        <Redirect from="/" to="/admin/dashboard" />
                    </Switch>
                </Router>
            </IntlProvider>
        );
    }
}

export default App