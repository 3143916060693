import React, { Component } from "react";
import axios from "axios";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import { FormattedNumber } from 'react-intl';
import '../../assets/css/tables-custom.css';
import Notify from 'react-notification-alert';


import ImportMetlifeCommissionModal from "../../components/Commissions/ImportMetlifeCommissionModal";
import MetlifeCommissionGroupModal from "../../components/Commissions/MetlifeCommissionGroupModal";
import ExportMetlifeCommissionRecordModal from "../../components/Commissions/ExportMetlifeCommissionRecordModal";
import DeleteMetlifeCommissionImportModal from "../../components/Commissions/DeleteMetlifeCommissionImportModal";

class MetlifeCommissionsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            pages: null,
            data: [],
            selected: null,
            showDetail: false,
            showExport: false,
            showImport: false,
            showDelete: false
        };

        this.table = React.createRef();

        this.fetchData = this.fetchData.bind(this);
        this.toggleGroup = this.toggleGroup.bind(this);
        this.onDetailClosed = this.onDetailClosed.bind(this);
        this.refetchData = this.refetchData.bind(this);
        this.toggleExport = this.toggleExport.bind(this);
        this.toggleImport = this.toggleImport.bind(this);
        this.toggleDeleteImport = this.toggleDeleteImport.bind(this);
    }

    toggleDeleteImport(show) {
        this.setState({
            showDelete: show
        });
    }

    toggleImport(show) {
        this.setState({
            showImport: show
        });
    }

    toggleExport(show, grouped) {
        this.setState({
            showExport: show,
            exportGroupedRecords: grouped
        });
    }

    toggleGroup(id) {
        this.setState({
            selected: id,
            showDetail: !this.state.showDetail
        });
    }

    showNotification(message) {
        this.refs.notify.notificationAlert({
            place: "tr",
            message: message,
            type: "success",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 0
        }); 
    }

    onDetailClosed() {
        this.setState({
            selected: null,
            showDetail: false
        });

        this.refetchData();
    }

    refetchData() {
        this.fetchData(this.table.current.state.page, this.table.current.state.pageSize, this.table.current.state.sorted, this.table.current.state.filtered);
    }

    fetchData(page, pageSize, sorted, filtered) {
        const params = new URLSearchParams();
        params.append('page', page + 1);
        params.append('pageSize', pageSize);

        for (var index in filtered) {
            params.append(filtered[index].id, filtered[index].value);
        }

        if (sorted.length === 1) {
            params.append("sortField", sorted[0].id)
            params.append("sortOrder", sorted[0].desc ? "Descending" : "Ascending");
        }

        this.setState({ loading: true });
        axios.get("/api/commissions/metlife/groups", { params: params }).then((response) => {
            this.setState({
                loading: false,
                data: response.data.items,
                pages: response.data.pages
            });
        }).catch((error) => {
            this.setState({
                loading: false
            });
            console.log(error);
        })
    }

    render() {
        const { data, pages, loading } = this.state;
        return (
            <>
                <div className="content">
                    <Col md={8} className="ml-auto mr-auto">
                        <h2 className="text-center">Tabuľka provízií Metlife</h2>
                        <p className="text-center">
                            Základný popis tejto tabuľky a funkcií, ktoré sa tu dajú spraviť
                        </p>
                    </Col>
                    <Row className="mt-5">
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <div className="tools float-right">
                                        <ImportMetlifeCommissionModal buttonLabel="Import" onImport={this.refetchData} />
                                        <UncontrolledDropdown>
                                            <DropdownToggle caret className="btn-icon" color="link" data-toggle="dropdown" type="button">
                                                <i className="tim-icons icon-settings-gear-63" />
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem onClick={() => this.toggleImport(true)}>Importovať záznamy</DropdownItem>
                                                <DropdownItem onClick={() => this.toggleExport(true, true)}>Exportovať záznamy</DropdownItem>
                                                <DropdownItem onClick={() => this.toggleExport(true, false)}>Exportovať nezlúčené záznamy</DropdownItem>
                                                <DropdownItem onClick={() => this.toggleDeleteImport(true)} className="text-danger">Zmazať import</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                    <CardTitle tag="h4">Metlife provízie</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        ref={this.table}
                                        resizable={false}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        columns={[
                                            {
                                                Header: "Kód brokra",
                                                accessor: "agent"
                                            },
                                            {
                                                Header: "Poistný návrh",
                                                accessor: "applno",
                                            },
                                            {
                                                Header: "Poistná zmluva",
                                                accessor: "polnum",
                                            },
                                            {
                                                Header: "Meno poisteného",
                                                accessor: "iName2"
                                            },
                                            {
                                                Header: "Provízia",
                                                accessor: "provision",
                                                filterable: false,
                                                Cell: props => <FormattedNumber value={props.value} />
                                            },
                                            {
                                                Header: "Storno",
                                                accessor: "storno",
                                                filterable: false,
                                                Cell: props => <FormattedNumber value={props.value} />
                                            },
                                            {
                                                Header: "Jednotky",
                                                accessor: "units",
                                                filterable: false,
                                                Cell: props => <FormattedNumber value={props.value} />
                                            }
                                        ]}
                                        manual
                                        data={data}
                                        pages={pages}
                                        loading={loading}
                                        onFetchData={(state, instance) => this.fetchData(state.page, state.pageSize, state.sorted, state.filtered)}
                                        filterable
                                        defaultPageSize={10}
                                        className="-striped -highlight -clickable"
                                        getTrProps={(state, rowInfo, column, instance) => ({
                                            onClick: e => this.toggleGroup(rowInfo.original.id)
                                        })}
                                        previousText = "Predošlá"
                                        nextText = "Ďalšia"
                                        loadingText = "Loading..."
                                        noDataText = "Žiadne záznamy"
                                        pageText = "Strana"
                                        ofText = "z"
                                        rowsText = "záznamov"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Notify ref="notify" />
                {this.state.showDetail && <MetlifeCommissionGroupModal id={this.state.selected} show={this.state.showDetail} toggle={this.toggleGroup} onClose={this.onDetailClosed} />}
                {this.state.showExport && <ExportMetlifeCommissionRecordModal grouped={this.state.exportGroupedRecords} show={this.state.showExport} onClose={() => this.toggleExport(false)} />}
                {this.state.showImport && <ImportMetlifeCommissionModal show={this.state.showImport} onClose={() => { this.toggleImport(false); this.refetchData(); }} onFinish={() => { this.showNotification("Import úspešne dokončený"); } } />}
                {this.state.showDelete && <DeleteMetlifeCommissionImportModal show={this.state.showDelete} onClose={() => { this.toggleDeleteImport(false); this.refetchData(); }} onFinish={() => { this.showNotification("Import úspešne zmazaný"); }} />}
            </>
        );
    }
}

export default MetlifeCommissionsTable;