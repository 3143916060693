import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

import { connect } from "react-redux";
// reactstrap components
import { Button, Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarBrand, Navbar, NavLink, Nav, Container, UncontrolledTooltip } from "reactstrap";

import { unauthorizeUser } from "../../store/actions/authActions";

class AdminNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseOpen: false,
            modalSearch: false,
            color: "navbar-transparent"
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateColor);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateColor);
    }
    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    updateColor = () => {
        if (window.innerWidth < 993 && this.state.collapseOpen) {
            this.setState({
                color: "bg-white"
            });
        } else {
            this.setState({
                color: "navbar-transparent"
            });
        }
    };
    // this function opens and closes the collapse on small devices
    toggleCollapse = () => {
        if (this.state.collapseOpen) {
            this.setState({
                color: "navbar-transparent"
            });
        } else {
            this.setState({
                color: "bg-white"
            });
        }
        this.setState({
            collapseOpen: !this.state.collapseOpen
        });
    };
    // this function is to open the Search modal
    toggleModalSearch = () => {
        this.setState({
            modalSearch: !this.state.modalSearch
        });
    };

    logout = () => {
        this.props.logout();
    };

    render() {
        return (
            <>
                <Navbar
                    className={classNames("navbar-absolute", {
                        [this.state.color]:
                            this.props.location.pathname.indexOf("full-screen-map") === -1
                    })}
                    expand="lg"
                >
                    <Container fluid>
                        <div className="navbar-wrapper">
                            <div className="navbar-minimize d-inline">
                                <Button
                                    className="minimize-sidebar btn-just-icon"
                                    color="link"
                                    id="tooltip209599"
                                    onClick={this.props.handleMiniClick}
                                >
                                    <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                                    <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip209599"
                                    placement="right"
                                >
                                    Sidebar toggle
                </UncontrolledTooltip>
                            </div>
                            <div
                                className={classNames("navbar-toggle d-inline", {
                                    toggled: this.props.sidebarOpened
                                })}
                            >
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    onClick={this.props.toggleSidebar}
                                >
                                    <span className="navbar-toggler-bar bar1" />
                                    <span className="navbar-toggler-bar bar2" />
                                    <span className="navbar-toggler-bar bar3" />
                                </button>
                            </div>
                            <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                                {this.props.brandText}
                            </NavbarBrand>
                        </div>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navigation"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={this.toggleCollapse}
                        >
                            <span className="navbar-toggler-bar navbar-kebab" />
                            <span className="navbar-toggler-bar navbar-kebab" />
                            <span className="navbar-toggler-bar navbar-kebab" />
                        </button>
                        <Collapse navbar isOpen={this.state.collapseOpen}>
                            <Nav className="ml-auto" navbar>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle
                                        caret
                                        color="default"
                                        data-toggle="dropdown"
                                        nav
                                        onClick={e => e.preventDefault()}
                                    >
                                        <div className="photo">
                                            <img alt="..." src={require("../../assets/img/default-avatar.png")} />
                                        </div>
                                        <b className="caret d-none d-lg-block d-xl-block" />
                                        <p className="d-lg-none">Odhlásiť sa</p>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-navbar" right tag="ul">
                                        <NavLink tag="li">
                                            <DropdownItem className="nav-item" onClick={this.logout}>Odhlásiť sa</DropdownItem>
                                        </NavLink>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <li className="separator d-lg-none" />
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        username: state.auth.username,
        displayName: state.auth.displayName
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(unauthorizeUser()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminNavbar);