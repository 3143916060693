import Register from "./views/pages/Register.jsx";
import Login from "./views/pages/Login.jsx";

const routes = [
    {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth"
    },
    {
        path: "/register",
        name: "Register",
        mini: "R",
        component: Register,
        layout: "/auth"
    }];

export default routes;
