import React from 'react';
import axios from 'axios';
import '../../assets/css/modal-form-controls.css';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Row, Col } from 'reactstrap';
import { FormattedNumber, FormattedDate } from 'react-intl';

class MetlifeCommissionRecordModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: props.show,
            data: null
        };

        this.toggle = this.toggle.bind(this);
        this.fetchData = this.fetchData.bind(this);

        this.fetchData(props.id);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }


    fetchData(id) {
        axios.get("/api/commissions/metlife/record/" + id).then((response) => {
            this.setState({
                data: response.data
            });
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {
        return (
            this.state.data && <React.Fragment>
                <Modal isOpen={this.state.modal} toggle={this.toggle} onClosed={this.props.onClose} className={this.props.className} size="lg">
                    <ModalHeader toggle={this.toggle}>Detail záznamu</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="3">
                                <strong><label>AGENT</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.agent}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>APPLNO</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.applno}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>POLNUM</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.polnum}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>INAME</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.iName2}</label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <strong><label>BMODE</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.bMode}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>YYMM</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.yymm}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>STATI</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.stati}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>PREMIUM</label></strong>
                                <FormGroup>
                                    <label><FormattedNumber value={this.state.data.premium} /></label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <strong><label>RATE</label></strong>
                                <FormGroup>
                                    <label><FormattedNumber value={this.state.data.rate} /></label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>STORNO</label></strong>
                                <FormGroup>
                                    <label><FormattedNumber value={this.state.data.storno} /></label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>PROVISION</label></strong>
                                <FormGroup>
                                    <label><FormattedNumber value={this.state.data.provision} /></label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>CMTYPCA</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.cmTypCa}</label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <strong><label>LOB</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.lob}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>DURATION</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.duration}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>INDEXCOMM</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.indexComm}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>MATEXP</label></strong>
                                <FormGroup>
                                    <label><FormattedDate value={this.state.data.matExp} /></label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <strong><label>RIDER</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.rider}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>CURR</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.curr}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>PLANCODE</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.planCode}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>MMID</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.mmid}</label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <strong><label>PAYDATETO</label></strong>
                                <FormGroup>
                                    <label><FormattedDate value={this.state.data.payDateTo} /></label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>OWNPOLICY</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.ownPolicy}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>ONAME</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.oName}</label>  
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>T2DATE</label></strong>
                                <FormGroup>
                                    <label><FormattedDate value={this.state.data.t2Date} /></label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <strong><label>CBISSDT</label></strong>
                                <FormGroup>
                                    <label><FormattedDate value={this.state.data.cbIssDt} /></label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>CBSTATUS</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.cbStatus}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>T2Situ</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.incno}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>INCNO</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.iName2}</label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <strong><label>PAIDAMT</label></strong>
                                <FormGroup>
                                    <label><FormattedNumber value={this.state.data.paidAmt} /></label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>CWBPDPREM</label></strong>
                                <FormGroup>
                                    <label><FormattedNumber value={this.state.data.cwbpdprem} /></label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>CWBCMRATE</label></strong>
                                <FormGroup>
                                    <label><FormattedNumber value={this.state.data.cwbcmRate} /></label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>CWBCM</label></strong>
                                <FormGroup>
                                    <label><FormattedNumber value={this.state.data.cwbcm} /></label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <strong><label>CWBRATE</label></strong>
                                <FormGroup>
                                    <label><FormattedNumber value={this.state.data.cwbRate} /></label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>CWBCLS</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.cwbcls}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>INAME2</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.iName2}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>CMTYPCA</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.cmTypCa}</label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <strong><label>OVERDUE</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.overdue}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>PAYNUM</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.payNum}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>PCDIDP01</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.pcdidp01}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>NAME</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.name}</label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <strong><label>CXMPREM</label></strong>
                                <FormGroup>
                                    <label><FormattedNumber value={this.state.data.cxmPrem} /></label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </Modal>
            </React.Fragment >
        );
    }
}

export default MetlifeCommissionRecordModal;