import React from 'react';
import axios from 'axios';
import '../../assets/css/modal-form-controls.css';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Row, Col, Input, Button } from 'reactstrap';
import { injectIntl } from "react-intl";
import ButtonLoader from "../../components/Buttons/ButtonLoader";

class ExportMetlifeCommissionRecordModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: props.show,
            options: [],
            id: null,
            exportGroupedRecords: props.grouped
        };

        this.button = React.createRef();

        this.toggle = this.toggle.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleExportClick = this.handleExportClick.bind(this);
        this.onImportSelected = this.onImportSelected.bind(this);

        this.fetchData();
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    onImportSelected(e) {
        this.setState({
            id: e.target.value
        })
    }

    handleExportClick(event, onSuccess, onError) {
        this.toggle();
        return window.location = (this.state.exportGroupedRecords ? "/api/commissions/metlife/export/" : "/api/commissions/metlife/exportnotgrouped/") + this.state.id;
    }

    fetchData() {
        axios.get("/api/commissions/metlife/imports").then((response) => {
            this.setState({
                options: response.data,
            });
            if (response.data.length > 0) {
                this.setState({
                    id: response.data[0].id
                });
            }
        }).catch((error) => {
            this.button.current.stopFetching();
        })
    }

    render() {
        return (
            this.state.options && <React.Fragment>
                <Modal isOpen={this.state.modal} toggle={this.toggle} onClosed={this.props.onClose} className={this.props.className} >
                    <ModalHeader toggle={this.toggle}>Export Metlife provízií</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <strong><label>Vyber import</label></strong>
                                <FormGroup>
                                    <Input type="select" name="select" id="ImportSelect" onChange={this.onImportSelected}>
                                        {this.state.options.map((value, index) => <option value={value.id} key={value.id}>Import č. {value.id} - {this.props.intl.formatDate(value.created)} {this.props.intl.formatTime(value.created)}</option>)}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonLoader ref={this.button} color="danger" onClick={this.handleExportClick}>Exportovať</ButtonLoader>
                        <Button color="secondary" onClick={this.toggle}>Zrušiť</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment >
        );
    }
}

export default injectIntl(ExportMetlifeCommissionRecordModal);