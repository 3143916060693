/* global localStorage:true */

export const loggedIn = (expirationTime) => {
    expirationTime = expirationTime ? expirationTime : localStorage.getItem('tokenExpirationTime')

    let time = new Date().getTime();
    let parsedExpirationTime = parseInt(expirationTime, 10);
    let logged = time / 1000 < parsedExpirationTime;

    return logged;
}

export const unlogin = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('tokenExpirationTime')
}
