import React, { Component } from "react";
import { Button } from "reactstrap";

class ButtonLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            color: props.color,
            onClick: props.onClick,
            className: props.className,
            text: props.children
        };
    }

    fetchData = () => {
        this.setState({
            loading: true
        });
    }

    stopFetching = () => {
        this.setState({
            loading: false
        });
    }

    render() {
        const { loading } = this.state;

        return (
            <React.Fragment>
                <Button color={this.state.color} onClick={(e) => { this.fetchData(); this.state.onClick(e); }} className={this.state.className} disabled={loading} type={this.props.type}>
                    {loading && <i className="tim-icons icon-refresh-02 spin" />}
                    {" "}
                    {this.state.text}
                </Button>
            </React.Fragment>  
        );
    }
}

export default ButtonLoader;