import VectorMap from "./views/maps/VectorMap.jsx";
import GoogleMaps from "./views/maps/GoogleMaps.jsx";
import FullScreenMap from "./views/maps/FullScreenMap.jsx";
import ReactTables from "./views/tables/ReactTables.jsx";
import RegularTables from "./views/tables/RegularTables.jsx";
import ExtendedTables from "./views/tables/ExtendedTables.jsx";
import Wizard from "./views/forms/Wizard.jsx";
import ValidationForms from "./views/forms/ValidationForms.jsx";
import ExtendedForms from "./views/forms/ExtendedForms.jsx";
import RegularForms from "./views/forms/RegularForms.jsx";
import Calendar from "./views/Calendar.jsx";
import Widgets from "./views/Widgets.jsx";
import Charts from "./views/Charts.jsx";
import Dashboard from "./views/Dashboard.jsx";
import Buttons from "./views/components/Buttons.jsx";
import SweetAlert from "./views/components/SweetAlert.jsx";
import Notifications from "./views/components/Notifications.jsx";
import Grid from "./views/components/Grid.jsx";
import Typography from "./views/components/Typography.jsx";
import Panels from "./views/components/Panels.jsx";
import Icons from "./views/components/Icons.jsx";
import Pricing from "./views/pages/Pricing.jsx";
import Register from "./views/pages/Register.jsx";
import Timeline from "./views/pages/Timeline.jsx";
import User from "./views/pages/User.jsx";
import Login from "./views/pages/Login.jsx";
import Lock from "./views/pages/Lock.jsx";
import MetlifeCommissionsTable from "./views/commissions/MetlifeCommissionsTable.jsx";
import MetlifeCommissionCoefficientRateConfigurationsTable from "./views/commissions/MetlifeCommissionCoefficientRateConfigurationsTable";

const routes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "tim-icons icon-chart-pie-36",
        component: Dashboard,
        layout: "/admin"
    },
    {
        collapse: true,
        name: "Provízie",
        icon: "tim-icons icon-money-coins",
        state: "commissionsCollapse",
        views: [
            {
                path: "/provizie/metlife",
                name: "Metlife",
                mini: "M",
                component: MetlifeCommissionsTable,
                layout: "/admin"
            },
            {
                collapse: true,
                name: "Konfigurácia",
                mini: "K",
                state: "multiCollapse",
                views: [
                    {
                        path: "/provizie/konfiguracie/metlife/koefrate",
                        name: "Metlife - nastavenie koef. rate",
                        mini: "KR",
                        component: MetlifeCommissionCoefficientRateConfigurationsTable,
                        layout: "/admin"
                    }
                ]
            }
        ]
    },
    //{
    //    collapse: true,
    //    name: "Pages",
    //    icon: "tim-icons icon-image-02",
    //    state: "pagesCollapse",
    //    views: [
    //        {
    //            path: "/pricing",
    //            name: "Pricing",
    //            mini: "P",
    //            component: Pricing,
    //            layout: "/auth"
    //        },
    //        {
    //            path: "/timeline",
    //            name: "Timeline",
    //            mini: "T",
    //            component: Timeline,
    //            layout: "/admin"
    //        },
    //        {
    //            path: "/login",
    //            name: "Login",
    //            mini: "L",
    //            component: Login,
    //            layout: "/auth"
    //        },
    //        {
    //            path: "/register",
    //            name: "Register",
    //            mini: "R",
    //            component: Register,
    //            layout: "/auth"
    //        },
    //        {
    //            path: "/lock-screen",
    //            name: "Lock Screen",
    //            mini: "LS",
    //            component: Lock,
    //            layout: "/auth"
    //        },
    //        {
    //            path: "/user-profile",
    //            name: "User Profile",
    //            mini: "UP",
    //            component: User,
    //            layout: "/admin"
    //        }
    //    ]
    //},
    //{
    //    collapse: true,
    //    name: "Components",
    //    icon: "tim-icons icon-molecule-40",
    //    state: "componentsCollapse",
    //    views: [
    //        {
    //            collapse: true,
    //            name: "Multi Level Collapse",
    //            mini: "MLT",
    //            state: "multiCollapse",
    //            views: [
    //                {
    //                    path: "/buttons",
    //                    name: "Buttons",
    //                    mini: "B",
    //                    component: Buttons,
    //                    layout: "/admin"
    //                }
    //            ]
    //        },
    //        {
    //            path: "/buttons",
    //            name: "Buttons",
    //            mini: "B",
    //            component: Buttons,
    //            layout: "/admin"
    //        },
    //        {
    //            path: "/grid-system",
    //            name: "Grid System",
    //            mini: "GS",
    //            component: Grid,
    //            layout: "/admin"
    //        },
    //        {
    //            path: "/panels",
    //            name: "Panels",
    //            mini: "P",
    //            component: Panels,
    //            layout: "/admin"
    //        },
    //        {
    //            path: "/sweet-alert",
    //            name: "Sweet Alert",
    //            mini: "SA",
    //            component: SweetAlert,
    //            layout: "/admin"
    //        },
    //        {
    //            path: "/notifications",
    //            name: "Notifications",
    //            mini: "N",
    //            component: Notifications,
    //            layout: "/admin"
    //        },
    //        {
    //            path: "/icons",
    //            name: "Icons",
    //            mini: "I",
    //            component: Icons,
    //            layout: "/admin"
    //        },
    //        {
    //            path: "/typography",
    //            name: "Typography",
    //            mini: "T",
    //            component: Typography,
    //            layout: "/admin"
    //        }
    //    ]
    //},
    //{
    //    collapse: true,
    //    name: "Forms",
    //    icon: "tim-icons icon-notes",
    //    state: "formsCollapse",
    //    views: [
    //        {
    //            path: "/regular-forms",
    //            name: "Regular Forms",
    //            mini: "RF",
    //            component: RegularForms,
    //            layout: "/admin"
    //        },
    //        {
    //            path: "/extended-forms",
    //            name: "Extended Forms",
    //            mini: "EF",
    //            component: ExtendedForms,
    //            layout: "/admin"
    //        },
    //        {
    //            path: "/validation-forms",
    //            name: "Validation Forms",
    //            mini: "VF",
    //            component: ValidationForms,
    //            layout: "/admin"
    //        },
    //        {
    //            path: "/wizard",
    //            name: "Wizard",
    //            mini: "W",
    //            component: Wizard,
    //            layout: "/admin"
    //        }
    //    ]
    //},
    //{
    //    collapse: true,
    //    name: "Tables",
    //    icon: "tim-icons icon-puzzle-10",
    //    state: "tablesCollapse",
    //    views: [
    //        {
    //            path: "/regular-tables",
    //            name: "Regular Tables",
    //            mini: "RT",
    //            component: RegularTables,
    //            layout: "/admin"
    //        },
    //        {
    //            path: "/extended-tables",
    //            name: "Extended Tables",
    //            mini: "ET",
    //            component: ExtendedTables,
    //            layout: "/admin"
    //        },
    //        {
    //            path: "/react-tables",
    //            name: "React Tables",
    //            mini: "RT",
    //            component: ReactTables,
    //            layout: "/admin"
    //        }
    //    ]
    //},
    //{
    //    collapse: true,
    //    name: "Maps",
    //    icon: "tim-icons icon-pin",
    //    state: "mapsCollapse",
    //    views: [
    //        {
    //            path: "/google-maps",
    //            name: "Google Maps",
    //            mini: "GM",
    //            component: GoogleMaps,
    //            layout: "/admin"
    //        },
    //        {
    //            path: "/full-screen-map",
    //            name: "Full Screen Map",
    //            mini: "FSM",
    //            component: FullScreenMap,
    //            layout: "/admin"
    //        },
    //        {
    //            path: "/vector-map",
    //            name: "Vector Map",
    //            mini: "VM",
    //            component: VectorMap,
    //            layout: "/admin"
    //        }
    //    ]
    //},
    //{
    //    path: "/widgets",
    //    name: "Widgets",
    //    icon: "tim-icons icon-settings",
    //    component: Widgets,
    //    layout: "/admin"
    //},
    //{
    //    path: "/charts",
    //    name: "Charts",
    //    icon: "tim-icons icon-chart-bar-32",
    //    component: Charts,
    //    layout: "/admin"
    //},
    //{
    //    path: "/calendar",
    //    name: "Calendar",
    //    icon: "tim-icons icon-time-alarm",
    //    component: Calendar,
    //    layout: "/admin"
    //}
];

export default routes;
