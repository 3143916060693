import { takeLatest, put, call } from "redux-saga/effects";
import * as a from "../actions/dashboardActions.jsx";
import axios from "axios";

function requestUnitRecordAxios() {
    return axios.get("/api/commissions/metlife/report/units");
}


function* unitReportRequest() {
    yield put(a.unitReportRequestStarting());

    try {
        let { data } = yield call(requestUnitRecordAxios);

        let { hasError, errorMessage } = data;

        if (hasError) {
            yield put(a.unitReportRequestError(errorMessage));
        } else {
            yield put(a.unitReportRequestSuccess(data));
        }
    } catch (error) {
        yield put(a.unitReportRequestError('UNIT_REPORTS_ERROR'));
    }
}

export function* watchDashboardRequests() {
    yield takeLatest(a.unitReportRequest, unitReportRequest);
}