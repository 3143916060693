import { createReducer } from "redux-act";
import * as a from "../actions/dashboardActions";

const getDefaultState = _ => ({
    unitReport: [],
    loadingUnits: false,
    loadingUnitsError: false,
    loadingUnitsErrorMessage: null
})

const dashboardReducer = createReducer(
    {
        [a.unitReportRequestStarting]: (state) => {
            return {
                ...state,
                loadingUnits: true,
                loadingUnitsError: false,
                loadingUnitsErrorMessage: null
            }
        },
        [a.unitReportRequestSuccess]: (state, { data }) => {
            return {
                ...state,
                unitReport: data,
                loadingUnits: false,
                loadingUnitsError: false,
                loadingUnitsErrorMessage: null
            }
        },
        [a.unitReportRequestError]: (state, payload) => {
            return {
                ...state,
                loadingUnits: false,
                loadingUnitsError: true,
                loadingUnitsErrorMessage: payload
            }
        }
    },
    getDefaultState()
);

export default dashboardReducer;