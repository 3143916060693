import React, { Component } from "react";
import axios from "axios";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import { FormattedNumber, FormattedDate, FormattedTime } from 'react-intl';
import '../../assets/css/tables-custom.css';
import MetlifeCommissionCoefficientRateConfigurationModal from "../../components/Commissions/MetlifeCommissionCoefficientRateConfigurationModal";
import SweetAlert from 'react-bootstrap-sweetalert';

class MetlifeCommissionCoefficientRateConfigurationsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            pages: null,
            data: [],
            selected: null,
            showDetail: false,
            alert: null
        };

        this.table = React.createRef();

        this.fetchData = this.fetchData.bind(this);
        this.toggleDetail = this.toggleDetail.bind(this);
        this.onDetailClosed = this.onDetailClosed.bind(this);
        this.deleteRecord = this.deleteRecord.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    }

    deleteRecord(id) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    showCancel
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Pozor"
                    onConfirm={() => this.hideAlert(true, id)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="danger"
                    cancelBtnText="Nie"
                    confirmBtnText="Zmazať"
                    reverseButtons
                >
                    Naozaj chcete zmazať zvolenú konfiguráciu?
            </SweetAlert>
            )
        });
    }

    hideAlert(confirmed, id) {
        this.setState({
            alert: null
        });

        if (confirmed == true && id) {
            axios.post("/api/commissions/metlife/deletecoefficientrateconfiguration/" + id).then((response) => {
                this.fetchData(this.table.current.state.page, this.table.current.state.pageSize, this.table.current.state.sorted, this.table.current.state.filtered);
            }).catch((error) => {
                console.log(error);
            })
        }
    }

    fetchData(page, pageSize, sorted, filtered) {
        const params = new URLSearchParams();
        params.append('page', page + 1);
        params.append('pageSize', pageSize);

        for (var index in filtered) {
            params.append(filtered[index].id, filtered[index].value);
        }

        if (sorted.length === 1) {
            params.append("sortField", sorted[0].id)
            params.append("sortOrder", sorted[0].desc ? "Descending" : "Ascending");
        }

        this.setState({ loading: true });
        axios.get("/api/commissions/metlife/coefficientrateconfigurations", { params: params }).then((response) => {
            this.setState({
                loading: false,
                data: response.data.items,
                pages: response.data.pages
            });
        }).catch((error) => {
            this.setState({
                loading: false
            });
            console.log(error);
        })
    }

    toggleDetail(id) {
        this.setState({
            selected: id,
            showDetail: true
        });
    }

    onDetailClosed() {
        this.setState({
            selected: undefined,
            showDetail: false
        });

        this.fetchData(this.table.current.state.page, this.table.current.state.pageSize, this.table.current.state.sorted, this.table.current.state.filtered);
    }

    render() {
        const { data, pages, loading } = this.state;
        return (
            <>
                <div className="content">
                    <Col md={8} className="ml-auto mr-auto">
                        <h2 className="text-center">Konfigurácia Metlife provízií - nastavenie koef. rate</h2>
                        <p className="text-center">
                            Základný popis tejto tabuľky a funkcií, ktoré sa tu dajú spraviť
                        </p>
                    </Col>
                    <Row className="mt-5">
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Konfigurácia Metlife provízií - nastavenie koef. rate</CardTitle>
                                    <div className="tools float-right">
                                        <Button color="danger" onClick={() => { this.toggleDetail(0) }}>Nová konfigurácia</Button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        ref={this.table}
                                        resizable={false}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        columns={[
                                            {
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                Cell: props => <button className="btn btn-icon btn-round btn-sm btn-danger" onClick={() => this.deleteRecord(props.original.id)}><i className="tim-icons icon-trash-simple" /></button>,
                                                width: 50
                                            },
                                            {
                                                Header: "Id",
                                                accessor: "id",
                                                width: 50
                                            },
                                            {
                                                Header: "Kód brokra",
                                                accessor: "agent",
                                                Cell: props => props.value != null ? props.value : "všetky"
                                            },
                                            {
                                                Header: "Rate",
                                                accessor: "rate",
                                                Cell: props => props.value != null ? <FormattedNumber value={props.value} /> : "všetky"
                                            },
                                            {
                                                Header: "Koef. rate",
                                                accessor: "coefficientRate",
                                                Cell: props => <FormattedNumber value={props.value} />
                                            },
                                            {
                                                Header: "Stati",
                                                accessor: "allowedStati",
                                                Cell: props => props.value != null ? props.value.join() : "všetky"
                                            },
                                            {
                                                Header: "Lob",
                                                accessor: "allowedLob",
                                                Cell: props => props.value != null ? props.value.join() : "všetky"
                                            },
                                            {
                                                Header: "PCDIDP01",
                                                accessor: "allowedPCDIDP01",
                                                filterable: false,
                                                Cell: props => props.value != null ? props.value.join() : "všetky"
                                            },
                                            {
                                                Header: "Platnosť",
                                                accessor: "validDates",
                                                filterable: false,
                                                Cell: props => {
                                                    if (props.original.validFrom != null && props.original.validTo != null)
                                                        return <>od <FormattedDate value={props.original.validFrom} /> do <FormattedDate value={props.original.validTo} /></>;

                                                    if (props.original.validFrom != null && props.original.validTo == null)
                                                        return <>od <FormattedDate value={props.original.validFrom} /></>;

                                                    if (props.original.validFrom == null && props.original.validTo != null)
                                                        return <>do <FormattedDate value={props.original.validTo} /></>;

                                                    return ""
                                                }
                                            },
                                            {
                                                Header: "Vytvorené",
                                                accessor: "created",
                                                filterable: false,
                                                Cell: props => <><FormattedDate value={props.value} /> <FormattedTime value={props.value} /></>
                                            },
                                            {
                                                Header: "Naposledy upravené",
                                                accessor: "updated",
                                                filterable: false,
                                                Cell: props => <><FormattedDate value={props.value} /> <FormattedTime value={props.value} /></>
                                            }
                                        ]}
                                        manual
                                        data={data}
                                        pages={pages}
                                        loading={loading}
                                        onFetchData={(state, instance) => this.fetchData(state.page, state.pageSize, state.sorted, state.filtered)}
                                        filterable
                                        defaultPageSize={10}
                                        className="-striped -highlight -clickable"
                                        getTdProps={(state, rowInfo, column, instance) => ({
                                            onClick: e => {
                                                if (rowInfo && column.id != "actions")
                                                    this.toggleDetail(rowInfo.original.id)
                                            }
                                        })}
                                        previousText="Predošlá"
                                        nextText="Ďalšia"
                                        loadingText="Loading..."
                                        noDataText="Žiadne záznamy"
                                        pageText="Strana"
                                        ofText="z"
                                        rowsText="záznamov"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {this.state.selected != undefined && <MetlifeCommissionCoefficientRateConfigurationModal id={this.state.selected} show={this.state.showDetail} onClose={this.onDetailClosed} />}
                    {this.state.alert}
                </div>
            </>
        );
    }
}

export default MetlifeCommissionCoefficientRateConfigurationsTable;