import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class ProtectedRoute extends Component {
    render() {
        const { component: Component, ...rest } = this.props;

        return (
            <Route {...rest} render={
                props => {
                    if (this.props.isAuthenticated) {
                        return <Component {...props} />;
                    }
                    else {
                        return <Redirect to={"/auth/login"} />;
                    }
                }
            } />
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProtectedRoute);