import axios from "axios";

export const headers = () => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`
})

export const makePostOptions = () => ({
  method: 'POST',
  headers: headers(),
})

export const makePatchOptions = () => ({
  method: 'PATCH',
  headers: headers()
})

export const getOptions = () => ({
  method: 'GET',
  headers: headers()
})

export const deleteOptions = () => ({
  method: 'DELETE',
  mode: 'cors',
  headers: headers()
})

const getAxiosRequest = (url, options) => axios.get(url, options);
const postAxiosRequest = (url, data, options) => axios.post(url, data, options);
const patchAxiosRequest = (url, data, options) => axios.patch(url, data, options);
const deleteAxiosRequest = (url, options) => axios.delete(url, options);

export const get = url => getAxiosRequest(url, getOptions())
export const post = (url, data) => postAxiosRequest(url, data, makePostOptions())
export const patch = (url, data) => patchAxiosRequest(url, data, makePatchOptions())
export const del = (url, id) => deleteAxiosRequest(url + id, deleteOptions())