import { takeLatest, put, call } from "redux-saga/effects";
import * as a from "../actions/authActions";
import { post } from "../../utils/api";

function* loginRequest({ payload }) {
    yield put(a.loginRequestStarting());

    try {
        let { data } = yield call(post, '/api/auth/login', payload);

        let { hasError, errorMessage } = data;

        if (hasError) {
            yield put(a.loginRequestError(errorMessage));
        } else {
            yield put(a.loginRequestSuccess(data.data));
            payload.onSuccess(data.data);
        }
    } catch (error) {
        yield put(a.loginRequestError('LOGIN_ERROR'));
    }
}

export function* watchAuthRequests() {
    yield takeLatest(a.loginRequest, loginRequest);
}