import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";

import { sagaMiddleware } from "./store/middleware";
import configureStore from "./store/configureStore";
import { watchAuthRequests } from "./store/sagas/authSaga";
import { watchDashboardRequests } from "./store/sagas/dashboardSaga";

const store = configureStore();
sagaMiddleware.run(watchAuthRequests);
sagaMiddleware.run(watchDashboardRequests);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);