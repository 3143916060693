import React from 'react';
import axios from 'axios';
import '../../assets/css/modal-form-controls.css';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Row, Col, Input, Button, Table } from 'reactstrap';
import ButtonLoader from '../../components/Buttons/ButtonLoader';
import PerfectScrollbar from "perfect-scrollbar";
import MetlifeCommissionRecordModal from './MetlifeCommissionRecordModal';
import { FormattedNumber } from 'react-intl';

var ps;

class MetlifeCommissionGroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: props.show,
            importFile: null,
            id: props.id,
            selected: null,
            showDetail: false,
            data: null,
            edit: false
        };

        this.toggle = this.toggle.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.changeUnits = this.changeUnits.bind(this);
        this.changeEurToRpRatio = this.changeEurToRpRatio.bind(this);
        this.saveUnits = this.saveUnits.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.toggleRecord = this.toggleRecord.bind(this);
        this.onRecordClosed = this.onRecordClosed.bind(this);
    }

    toggleEdit() {
        if (this.state.edit)
            this.fetchData();

        this.setState(prevState => ({
            edit: !prevState.edit
        }));
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    toggleRecord(id) {
        this.setState(prevState => ({
            selected: id,
            showDetail: !prevState.showDetail
        }));
    }

    onRecordClosed() {
        this.setState({
            selected: null,
            showDetail: false
        });
    }

    changeUnits(id, e) {
        if (this.state.data) {
            const index = this.state.data.records.findIndex((record) => {
                return record.id === id;
            });

            var data = Object.assign({}, this.state.data);
            data.records[index].calculation.units = e.target.value;

            this.setState({
                data: data
            });
        }
    }

    changeEurToRpRatio(id, e) {
        if (this.state.data) {
            const index = this.state.data.records.findIndex((record) => {
                return record.id === id;
            });

            var data = Object.assign({}, this.state.data);
            data.records[index].calculation.eurToRpRatio = e.target.value;

            this.setState({
                data: data
            });
        }
    }

    saveUnits() {
        var request = Array.from(document.getElementsByClassName("commission-record-row")).map(function (value) {
            return {
                id: value.getAttribute("data-id"),
                eurToRpRatio: value.childNodes[6].firstChild.value,
                units: value.childNodes[7].firstChild.value
            }
        });
        axios.post("/api/commissions/metlife/updaterecordcalculations/", request).then((response) => {
            this.toggleEdit();
        }).catch((error) => {
            console.log(error);
        })
    }

    componentWillMount() {
        this.fetchData();
    }

    fetchData() {
        axios.get("/api/commissions/metlife/group/" + this.state.id).then((response) => {
            this.setState({
                data: response.data
            });

            // if you are using a Windows Machine, the scrollbars will have a Mac look
            if (navigator.platform.indexOf("Win") > -1) {
                var table = document.querySelector(".modal-dialog .table")
                if (table)
                    ps = new PerfectScrollbar(table);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    componentWillUnmount() {
        // we need to destroy the false scrollbar when we navigate
        // to a page that doesn't have this component rendered
        if (navigator.platform.indexOf("Win") > -1 && ps) {
            ps.destroy();
        }
    }

    render() {
        let edit = this.state.edit;
        let changeUnits = this.changeUnits;
        return (
            this.state.data != null && <React.Fragment>
                <Modal isOpen={this.state.modal} toggle={this.toggle} onClosed={this.props.onClose} className={this.props.className} size="lg">
                    <ModalHeader toggle={this.toggle}>Importované provízie Metlife</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="3">
                                <strong><label>Kód brokra</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.records[0].agent}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>Poistný návrh</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.records[0].applno}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>Poistná zmluva</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.records[0].polnum}</label>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <strong><label>Meno poistného</label></strong>
                                <FormGroup>
                                    <label>{this.state.data.records[0].iName2}</label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="summary-table">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Premium</th>
                                            <th>Storno</th>
                                            <th>Provízia</th>
                                            <th>Rate</th>
                                            <th>Koef. rate</th>
                                            <th>€ / RP</th>
                                            <th className="text-center">Jednotky</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.data.records.map((value, index) => {
                                                return (
                                                    <tr key={value.Id} className="commission-record-row" data-id={value.id}>
                                                        <td><a href="#" onClick={() => this.toggleRecord(value.id)}><i className="tim-icons icon-zoom-split"></i></a></td>
                                                        <td><FormattedNumber value={value.premium} /></td>
                                                        <td><FormattedNumber value={value.storno} /></td>
                                                        <td><FormattedNumber value={value.provision} /></td>
                                                        <td><FormattedNumber value={value.rate} /></td>
                                                        <td>{value.calculation ? <FormattedNumber value={value.calculation.coefficientRate} /> : "nevypočítané"}</td>
                                                        {edit && value.calculation ?
                                                            <>
                                                                <td><Input type="number" defaultValue={value.calculation ? value.calculation.eurToRpRatio : ""} onChange={this.changeEurToRpRatio.bind(this, value.id)} /></td>
                                                                <td className="text-center"><Input type="number" defaultValue={value.calculation ? value.calculation.units : ""} onChange={changeUnits.bind(this, value.id)} /></td>
                                                            </>
                                                            :
                                                            <>
                                                                <td>{value.calculation ? <><FormattedNumber value={value.calculation.eurToRpRatio} /> <span> %</span></> : "nevypočítané"}</td>
                                                                <td>{value.calculation ? <FormattedNumber value={value.calculation.units} /> : "nevypočítané"}</td>
                                                            </>
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr key="group">
                                            <td></td>
                                            <td><FormattedNumber value={this.state.data.premium} /></td>
                                            <td><FormattedNumber value={this.state.data.storno} /></td>
                                            <td><FormattedNumber value={this.state.data.provision} /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="text-center"><FormattedNumber value={this.state.data.units} /></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {
                            edit ? (
                                <React.Fragment>
                                    <ButtonLoader color="danger" onClick={this.saveUnits}>Uložiť</ButtonLoader>
                                    <Button color="secondary" onClick={this.toggleEdit}>Zrušiť editáciu</Button>
                                </React.Fragment>
                            ) : (
                                    <Button color="danger" onClick={this.toggleEdit}>Editovať</Button>
                                )
                        }
                    </ModalFooter>
                </Modal>
                {this.state.showDetail && <MetlifeCommissionRecordModal id={this.state.selected} show={this.state.showDetail} toggle={this.toggleRecord} onClose={this.onRecordClosed}  />}
            </React.Fragment >
        );
    }
}

export default MetlifeCommissionGroupModal;