import React from "react";
import { connect } from "react-redux";
import { Button, Card, CardHeader, CardBody, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Col } from "reactstrap";

import { loginRequest } from "../../store/actions/authActions";

class Login extends React.Component {
    state = {
        auth: {
            email: '',
            password: ''
        }
    };

    constructor(props) {
        super(props);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    onEmailChange = (e) => {
        var auth = this.state.auth;
        auth.email = e.target.value;
        this.setState({ auth });
    };

    onPasswordChange = (e) => {
        var auth = this.state.auth;
        auth.password = e.target.value;
        this.setState({ auth });
    }

    componentDidMount() {
        document.body.classList.toggle("login-page");
    }

    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }

    onSuccess() {
        document.location = "/";
    }

    getErrorMessage(messageId) {
        switch (messageId) {
            case "PASSWORD_INVALID":
                return "Prihlasovacie údaje nie sú správne."
            case "LOGIN_ERROR":
                return "Prihlásenie zlyhalo. Uistite sa, prosím, že sú vyplnené všetky polia.";
            case "USER_DOES_NOT_EXIST":
                return "Používateľ so zadaným emailom neexistuje.";
            default:
                return messageId;
        }
    }

    submit = (e) => {
        e.preventDefault();

        let payload = {
            email: this.state.auth.email,
            password: this.state.auth.password,
            onSuccess: this.onSuccess
        };

        this.props.login(payload);
    }

    render() {
        let loginButtonText = 'Prihlásiť sa';
        return (
            <>
                <div className="content">
                    <Container>
                        <Col className="ml-auto mr-auto" lg="4" md="6">
                            <Form className="form" onSubmit={this.submit} >
                                <Card className="card-login card-white">
                                    <CardHeader>
                                        <img
                                            alt="..."
                                            src={require("../../assets/img/winners-group-logo.png")}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="tim-icons icon-email-85" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="Email" type="email" onChange={this.onEmailChange} />
                                        </InputGroup>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="tim-icons icon-lock-circle" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="Heslo" type="password" onChange={this.onPasswordChange} />
                                        </InputGroup>
                                        <Button
                                            block
                                            className="mb-3"
                                            color="danger"
                                            type="submit"
                                            size="lg"
                                        >
                                            {loginButtonText}
                                        </Button>
                                        {this.props.auth.hasError && (
                                            <div style={{ textAlign: "center" }}>
                                                <span className="text-danger">
                                                    {this.getErrorMessage(this.props.auth.errorMessageId)}
                                                </span>
                                            </div>)
                                        }
                                    </CardBody>
                                </Card>
                            </Form>
                        </Col>
                    </Container>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: {
            hasError: state.auth.loginError,
            errorMessageId: state.auth.loginErrorMessage
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: payload => dispatch(loginRequest(payload))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
