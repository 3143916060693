import React from 'react';
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import '../../assets/css/modal-form-controls.css';
import { Button, Modal, ModalHeader, ModalBody, Input, ModalFooter, Form, FormGroup, Row, Col, Progress } from 'reactstrap';
import ButtonLoader from '../../components/Buttons/ButtonLoader';

class ImportMetlifeCommissionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: props.show,
            importFile: null,
            hubConnection: null,
            hubConnectionId: null,
            progress: 0
        };

        this.button = React.createRef();
        this.toggle = this.toggle.bind(this);
        this.handleUploadClick = this.handleUploadClick.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    componentDidMount = () => {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("/uploadfileprogress").build();

        this.setState({ hubConnection }, () => {
            this.state.hubConnection
                .start()
                .then(() => {
                    hubConnection.invoke('getConnectionId')
                        .then((connectionId) => {
                            this.setState({
                                hubConnectionId: connectionId
                            });
                        });
                })
                .catch(err => console.log(err));

            this.state.hubConnection.on('updateProgress', (progress) => {
                this.setState({ progress });
                if (progress == 100) {
                    this.button.current.stopFetching();
                    if (this.props.onFinish) {
                        this.props.onFinish();
                    }
                }
            });
        });
    }

    handleUploadClick(event) {
        var imagefile = document.querySelector('#MetlifeImportFile');
        event.preventDefault();
        var form = document.querySelector('#MetlifeImportForm');
        if (imagefile.files.length > 0) {
            var query = "";
            if (this.state.hubConnectionId)
                query = "?connectionId=" + this.state.hubConnectionId;
            var formData = new FormData(form);
            formData.append("File", imagefile.files[0]);
            axios.post(form.action + query, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {

            }).catch((error) => {
                    this.toggle()
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} onClosed={this.props.onClose}>
                    <ModalHeader toggle={this.toggle}>Import Metlife provízií</ModalHeader>
                    <Form action="/api/commissions/metlife/import" method="POST" id="MetlifeImportForm" >
                        <ModalBody>
                            <Row>
                                <Col>
                                    <label>Súbor</label>
                                    <div>
                                        <input type="file" id="MetlifeImportFile" required />
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonLoader color="danger" ref={this.button} onClick={this.handleUploadClick}>Importovať</ButtonLoader>
                            <Button color="secondary" onClick={this.toggle}>Zrušiť</Button>
                        </ModalFooter>
                        <div className="progress-container progress-danger">
                            <Progress max="100" value={this.state.progress} barClassName="progress-bar-primary" className="m-0" />
                        </div>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ImportMetlifeCommissionModal;