import React from "react";
import { Route, Switch } from "react-router-dom";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

// core components
import AdminNavbar from "../../components/Navbars/AdminNavbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/Sidebar.jsx";
import FixedPlugin from "../../components/FixedPlugin/FixedPlugin.jsx";

import routes from "../../routes.js";

import logo from "../../assets/img/winners-logo-white.jpg";

var ps;

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeColor: "red",
            sidebarMini: true,
            opacity: 0,
            sidebarOpened: false
        };
    }
    showNavbarButton = () => {
        if (
            document.documentElement.scrollTop > 50 ||
            document.scrollingElement.scrollTop > 50 ||
            this.refs.mainPanel.scrollTop > 50
        ) {
            this.setState({ opacity: 1 });
        } else if (
            document.documentElement.scrollTop <= 50 ||
            document.scrollingElement.scrollTop <= 50 ||
            this.refs.mainPanel.scrollTop <= 50
        ) {
            this.setState({ opacity: 0 });
        }
    };
    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        exact
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    getActiveRoute = routes => {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = this.getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.pathname.indexOf(
                        routes[i].layout + routes[i].path
                    ) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };
    handleActiveClick = color => {
        this.setState({ activeColor: color });
    };
    handleMiniClick = () => {
        document.body.classList.toggle("sidebar-mini");
    };
    toggleSidebar = () => {
        this.setState({
            sidebarOpened: !this.state.sidebarOpened
        });
        document.documentElement.classList.toggle("nav-open");
    };
    closeSidebar = () => {
        this.setState({
            sidebarOpened: false
        });
        document.documentElement.classList.remove("nav-open");
    };
    render() {
        return (
            <div className="wrapper">
                <div className="rna-container">
                    <NotificationAlert ref="notificationAlert" />
                </div>
                <div
                    className="navbar-minimize-fixed"
                    style={{ opacity: this.state.opacity }}
                >
                    <button
                        className="minimize-sidebar btn btn-link btn-just-icon"
                        onClick={this.handleMiniClick}
                    >
                        <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
                        <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
                    </button>
                </div>
                <Sidebar
                    {...this.props}
                    routes={routes}
                    activeColor={this.state.activeColor}
                    logo={{
                        outterLink: "https://www.winnersgroup.sk/",
                        text: "Winners Group",
                        imgSrc: logo
                    }}
                    closeSidebar={this.closeSidebar}
                />
                <div
                    className="main-panel"
                    ref="mainPanel"
                    data={this.state.activeColor}
                >
                    <AdminNavbar
                        {...this.props}
                        handleMiniClick={this.handleMiniClick}
                        brandText={this.getActiveRoute(routes)}
                        sidebarOpened={this.state.sidebarOpened}
                        toggleSidebar={this.toggleSidebar}
                    />
                    <Switch>{this.getRoutes(routes)}</Switch>
                    {// we don't want the Footer to be rendered on full screen maps page
                        this.props.location.pathname.indexOf("full-screen-map") !==
                            -1 ? null : (
                                <Footer fluid />
                            )}
                </div>
                <FixedPlugin
                    activeColor={this.state.activeColor}
                    sidebarMini={this.state.sidebarMini}
                    handleActiveClick={this.handleActiveClick}
                    handleMiniClick={this.handleMiniClick}
                />
            </div>
        );
    }
}

export default Admin;
