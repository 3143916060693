import React from 'react';
import axios from 'axios';
import '../../assets/css/modal-form-controls.css';
import { Button, Modal, ModalHeader, ModalBody, Input, ModalFooter, Form, FormGroup, Row, Col, FormText } from 'reactstrap';
import ButtonLoader from '../../components/Buttons/ButtonLoader';
import PerfectScrollbar from "perfect-scrollbar";
import Datetime from 'react-datetime';
import moment, { isDate, isMoment } from "moment";
import Select from 'react-select';
import { retry } from 'redux-saga/effects';
import { instanceOf } from 'prop-types';

var ps;

class MetlifeCommissionCoefficientRateConfigurationModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: props.show,
            id: props.id,
            data: null
        };
       

        this.toggle = this.toggle.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputArrayChange = this.handleInputArrayChange.bind(this);
        this.handleDateInputChange = this.handleDateInputChange.bind(this);

        this.button = React.createRef();
        this.fetchData(this.state.id);
    }

    fetchData(id) {
        axios.get("/api/commissions/metlife/coefficientrateconfiguration/" + id).then((response) => {
            var selectedYears = null;
            if (response.data.yearsToPay) {
                selectedYears = this.state.yearOptions.map((o) => {
                    if (response.data.yearsToPay.includes(o.value))
                        return o;
                });
                console.log(response.data.yearsToPay);
                console.log(selectedYears);
            }

            this.setState({
                data: response.data,
                selectedYears: selectedYears
            });

            // if you are using a Windows Machine, the scrollbars will have a Mac look
            if (navigator.platform.indexOf("Win") > -1) {
                var table = document.querySelector(".modal-dialog .table")
                if (table)
                    ps = new PerfectScrollbar(table);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    };

    handleSaveClick(e) {
        e.preventDefault();
        axios.post('/api/commissions/metlife/coefficientrateconfiguration/save', this.state.data)
            .then((result) => {
                this.props.onClose();
            }).catch((result) => {
                this.button.current.stopFetching();
            });
    };

    isDate(date) {
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }

    handleDateInputChange(property) {
        return e => {
            var value = null;
            if (isMoment(e)) {
                value = e.format('YYYY-MM-DD[T]HH:mm:ss.SSS');
            }
            var data = this.state.data;
            data[property] = value;
            this.setState({
                data: data
            });
        };
    }

    handleInputChange(property) {
        return e => {
            var data = this.state.data;
            data[property] = e.target.value;
            this.setState({
                data: data
            });
        };
    }

    handleInputArrayChange(property) {
        return e => {
            var data = this.state.data;
            if (e.target.value == "" || e.target.value == null) {
                data[property] = null;
            } else {
                data[property] = e.target.value.split(",");
            }
            this.setState({
                data: data
            });
        };
    }

    componentWillUnmount() {
        // we need to destroy the false scrollbar when we navigate
        // to a page that doesn't have this component rendered
        if (navigator.platform.indexOf("Win") > -1 && ps) {
            ps.destroy();
        }
    }

    render() {
        return (
            this.state.data != null && <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} onClosed={this.props.onClose} size="lg">
                <ModalHeader toggle={this.toggle}>Konfigurácia Metlife provízií - nastavenie koef. rate</ModalHeader>
                <Form>
                    <input type="hidden" value={this.state.id} />
                    <ModalBody>
                        <Row>
                            <Col md="4">
                                <label>Agent</label>
                                <FormGroup>
                                    <Input type="text" value={this.state.data.agent} name="Agent" onChange={this.handleInputChange("agent")} />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <label>Rate</label>
                                <FormGroup>
                                    <Input type="text" value={this.state.data.rate} name="Rate" onChange={this.handleInputChange("rate")} />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <label>Koef. Rate</label>
                                <FormGroup>
                                    <Input type="text" value={this.state.data.coefficientRate} name="CoefficientRate" onChange={this.handleInputChange("coefficientRate")} />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <label>Platné od</label>
                                <FormGroup>
                                    <Datetime timeFormat={false} inputProps={{ name: "ValidFrom" }} onChange={this.handleDateInputChange("validFrom")} defaultValue={this.state.data.validFrom != null ? moment(this.state.data.validFrom) : ""} />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <label>Platné do</label>
                                <FormGroup>
                                    <Datetime timeFormat={false} inputProps={{ name: "ValidTo" }} onChange={this.handleDateInputChange("validTo")} defaultValue={this.state.data.validTo != null ? moment(this.state.data.validTo) : ""} />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <label>STATI</label>
                                <FormGroup>
                                    <Input type="text" value={this.state.data.allowedStati} name="AllowedStati" onChange={this.handleInputArrayChange("allowedStati")} />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <label>Lob</label>
                                <FormGroup>
                                    <Input type="text" value={this.state.data.allowedLob} name="AllowedLob" onChange={this.handleInputArrayChange("allowedLob")} />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <label>PCDIDP01</label>
                                <FormGroup>
                                    <Input type="text" value={this.state.data.allowedPCDIDP01} name="AllowedPCDIDP01" onChange={this.handleInputArrayChange("allowedPCDIDP01")} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonLoader color="danger" type="submit" onClick={(e) => {
                            this.handleSaveClick(e, () => { this.toggle(); }, () => { this.toggle(); });
                        }} ref={this.button}>Uložiť</ButtonLoader>
                        <Button color="secondary" onClick={this.toggle}>Zrušiť</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

export default MetlifeCommissionCoefficientRateConfigurationModal;