import { createReducer } from "redux-act";
import * as a from "../actions/authActions";
import { takeIfExists } from '../../utils/localStorage'

import { loggedIn } from "../../utils/auth";

const getDefaultState = _ => ({
    token: takeIfExists('token'),
    tokenExpirationTime: takeIfExists('tokenExpirationTime', Number),
    username: takeIfExists('username'),
    displayName: takeIfExists('displayName'),
    isAuthenticated: loggedIn(),
    loggingIn: false,
    loginError: false,
    loginErrorMessage: null,
})

const authReducer = createReducer(
    {
        [a.unauthorizeUser]: () => ({}),

        [a.loginRequestStarting]: (state) => {
            return {
                ...state,
                loggingIn: true,
                loginError: false,
                loginErrorMessage: null
            }
        },
        [a.loginRequestSuccess]: (state, { token, tokenExpirationTime, username, displayName }) => {
            return {
                ...state,
                token,
                tokenExpirationTime,
                username,
                displayName,
                isAuthenticated: loggedIn(tokenExpirationTime),
                loggingIn: false,
                loginError: false,
                loginErrorMessage: null
            }
        },
        [a.loginRequestError]: (state, payload) => {
            return {
                ...state,
                loggingIn: false,
                loginError: true,
                loginErrorMessage: payload
            }
        }
    },
    getDefaultState()
);

export default authReducer;